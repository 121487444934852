<template>
    <div id="building">
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="building-container" v-else>
            <PageTitle :title="data.Title" :bg="url+data.DisplayImage.url" />
            <div class="building-description">
                <h2 class="div-title">
                    {{data.LongDescriptionTitle}}
                </h2>
                <p>
                    {{data.LongDescription}}
                </p>
            </div>
            <FeaturedProperties v-if="filteredProperties.length > 0" align="right" title="Exclusive Listings" :data="filteredProperties"/>
            <div class="more-listing-buttons">
                <a :href="leaseURL" class="btn" target="_blank">
                    More Listings For Lease
                </a>
                <a :href="saleURL" class="btn" target="_blank">
                    More Listings For Sale
                </a>
            </div>
            <div class="amenities">
                <div class="page-title">
                    <h2>
                        Amenities
                    </h2>
                </div>
                <vueper-slides
                    :bullets="false"
                    :slide-ratio="1 / 4"
                    :dragging-distance="70"
                    :fixed-height="adaptiveHeight"
                    prevent-y-scroll>
                    <vueper-slide
                        v-for="(slide, i) in data.Amenities"
                        :key="i"
                        :image="url+slide.url"
                        class="main_slides"
                        >
                    </vueper-slide>
                </vueper-slides>
            </div>
            <div class="floorplans">
                <h2 class="page-title">
                    Floor Plans
                </h2>
                <div class="plans-container">
                    <div v-for="(item, i) in sortedFloorplans" class="floorplan" :key="i">
                        <a :href="`${url}${item.FloorplanCarousel[0].url}`" target="_blank" rel="noopener noreferrer" class="floorplan-link">
                            <svg width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 6.94C15.9896 6.84813 15.9695 6.75763 15.94 6.67V6.58C15.8919 6.47718 15.8278 6.38267 15.75 6.3L9.75 0.3C9.66734 0.222216 9.57282 0.158081 9.47 0.11H9.38L9.06 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V7C16 7 16 7 16 6.94ZM10 3.41L12.59 6H10V3.41ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H14V17Z"/>
                            </svg>
                            <font-awesome-icon v-if="false" class="icon" :icon="['fa', 'file']" />
                            <span>{{item.Title}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { VueperSlides, VueperSlide } from 'vueperslides'
import PageTitle from "../components/PageTitle"
import FeaturedProperties from "../components/FeaturedProperties"


export default {
    name: "Building",
    components: {
        Spinner,
        FeaturedProperties,
        VueperSlides, 
        VueperSlide,
        PageTitle,
    },
    data(){
        return{
            title: "Building",
            data: {},
            url: 'https://strapi.uptowncreative.io',
            window: {
                width: 0,
                height: 0
            },
            loading: true,
            currentFloorplans: {},
        }
    },
    computed: {
        adaptiveHeight: function(){
            if (this.window.width > 576){
                return '95vh';
            } else {
                return '300px';
            }
        },
        floorAdaptiveHeight: function(){
            if (this.window.width < 1100 & this.window.height > 1000){
                return '40vh';
            }
            if (this.window.width > 576){
                return '80vh';
            } else {
                return '300px';
            }
        },
        filteredProperties:function(){
            const unfilteredProperties = this.data.coastal_luxe_properties.filter(
                (property) => {
                    return property.Status !== 'sold';
                }
            ).sort((a,b) => {
                return new Date(b.published_at) - new Date(a.published_at);
            });
            return unfilteredProperties;
        },
        selectOptions: function(){
            const floorplantitle = this.data.coastal_luxe_floorplans.map(
                (floorplan) => {
                    return floorplan.Title;
                }
            )
            return floorplantitle;
        },
        sortedFloorplans: function(){
            let sortedData = this.data.coastal_luxe_floorplans;
            sortedData = sortedData.sort(function(a, b){
                if(a.Title < b.Title) { return -1; }
                if(a.Title > b.Title) { return 1; }
                return 0;
            })
            return sortedData;
        },
        saleURL: function(){
            switch(this.$route.params.slug.toLowerCase()){
                case 'azzurra':
                    return 'https://idxhome.com/report/listing-report/Azzurra-For-Sale/89398/314339';
                case 'regatta':
                    return 'https://idxhome.com/report/listing-report/Regatta-For-Sale/89398/314413';
                case 'cove':
                    return 'https://idxhome.com/report/listing-report/Cove-For-Sale/89398/314346';
                default:
                    return 'https://idxhome.com/report/listing-report/Azzurra-For-Sale/89398/314339';
            }
        },
        leaseURL: function(){
            switch(this.$route.params.slug.toLowerCase()){
                case 'azzurra':
                    return 'https://idxhome.com/report/listing-report/Azzurra-For-Lease/89398/314340';
                case 'regatta':
                    return 'https://idxhome.com/report/listing-report/Regatta-For-Lease/89398/314414';
                case 'cove':
                    return 'https://idxhome.com/report/listing-report/Cove-For-Lease/89398/314347';
                default:
                    return 'https://idxhome.com/report/listing-report/Azzurra-For-Lease/89398/314340';
            }
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        updateFilterOption(value){
            this.currentFloorplans = this.data.coastal_luxe_floorplans.find(
                (item) => {
                    return item.Title === value;
                }
            )
        },
        retrieveData(){
            this.loading = true;
            this.axios.get(`https://strapi.uptowncreative.io/coastal-luxe-buildings?Title=${this.$route.params.slug}`)
            .then((response) => {
                this.data = response.data[0];
                this.loading = false;
            })
        }
    },
    mounted:function(){
        this.retrieveData();
    },
    watch:{
      $route(){
        this.retrieveData();
      }
    },
    metaInfo() {
         let data = this.data.Title; 
         return {
             title: data ? data : "Building",
             titleTemplate: '%s | Coastal Luxe',
             meta: [{
                name: 'description', 
                content: "Coastal Luxe"
            }]
         }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.building-description{
    margin-top: min(90vh - 180px, 445px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        width: 80%;
        max-width: 350px;
        font-size: 50px;
        text-transform: unset;
    }
    p{
        text-align: center;
        max-width: 700px;
        margin: 2rem;
    }
}
.floorplans-select{
    display: flex;
    align-content: center;
    justify-content: center;
    .filters{
        margin-left: 20px;
        margin-top: 5px;
    }
}
.floorplans-slideshow{
    margin: 2rem;
    border: 1px solid #aaa;
    h3{
        position: relative;
        text-align: center;
        margin: 0 auto;
        margin-top: -15px;
        background: white;
        width: 30%;
        min-width: 100px;
    }
    .slideshow{
        margin: 4rem 2rem;
    }
}

.plans-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 1024px;
    margin: 4rem auto;
}
.floorplan{
    width: 33.33%;
    padding-right: 3%;
    a{
        display: flex;
        width: 100%;
        height: 75px;
        align-items: center;
        svg{
            fill: #003785;
            margin-right: 10px;
            width: 15%;
            height: 30px;
        }
        span{
            font-size: 15px;
            color: #003785;
            font-weight: 600;
        }
    }
    a:hover{
        svg{
            fill: #0067d4;
        }
        span{
            color: #0067d4;
        }
    }
}

.more-listing-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media (max-width: $tablet) {
    .building-description{
        margin-top: 85vh
    }
    .floorplan{
        width: 50%;
    }
}
@media (max-width: $mobile) { 
    .building-description{
        margin-top: 100vw;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2{
            width: 80%;
            max-width: 350px;
            font-size: 50px;
            text-transform: unset;
        }
        p{
            text-align: center;
            max-width: 700px;
            margin: 2rem;
        }
    }
    .floorplan{
        width: 100%;
        padding-right: unset;
        margin-left: 2rem;
    }
}
</style>

<style lang="scss">
.floorplans{
    .floorplans-select{
        .filters{
            margin-top: 0;
            .multiselect{
                margin-top: 5px;
            }
        }
    }
}
.floorplans-slideshow{
    .vueperslides__arrows{
        color: #000;
    }
}
</style>